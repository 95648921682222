<template>
  <v-container fluid>
    <MyDialog ref="itemsDialog" @onConfirm="deleteItem" />
    <orders-modal ref="detailsModal" :selectedData="selectedData" />
    <MyDataTable
      title="سفارشات"
      :headers="headers"
      :items="items"
      :loading="loading"
      :pagesLength="pagesLength"
      @onPageChanged="GetList"
      class="mt-n13 moholand-shadow rounded-lg"
      :isEditEnabled="false"
      :isRemoveEnabled="false"
    >
      <template #middleButtonTag="{ row }">
        <v-btn
          class="mx-2"
          dark
          small
          color="primary"
          @click="onDetailsClick(row)"
        >
          <v-icon small dark>mdi-apps</v-icon>
          مشاهده
        </v-btn>
      </template>
      <template #contentTag="{ row }">
        <td>{{ row.code ? row.code + "#" : "-" }}</td>
        <td>{{ row.created_at | moment("jYYYY/jMM/jDD") }}</td>
        <!-- <td>{{ row.status }}</td> -->
        <td>
          <v-chip
            class="rounded-lg justify-center"
            text-color="white"
            label
            color="success"
            style="width: 100%"
          >
            {{ price(row) }}
          </v-chip>
        </td>
      </template>
    </MyDataTable>
  </v-container>
</template>

<script>
import MyAxios from "@/constants/MyAxios";
import MyDialog from "@/components/MyDialog";
import MyDataTable from "@/components/MyDataTable";
import OrdersModal from "@/components/Panel/OrdersModal.vue";

export default {
  created() {
    this.GetList();
  },
  components: {
    MyDialog,
    MyDataTable,
    OrdersModal,
  },
  methods: {
    GetList() {
      if (!this.loading) this.loading = true;

      MyAxios.get("/orders/index", {
        params: {
          with: ["orderProduct", "user", "payment"],
          page: this.$route.query?.page || 1,
          conditions: {
            user_id: this.$store.state?.userData?.id,
          },
        },
      })
        .then((response) => {
          console.log("orderssssss", response);
          this.loading = false;
          this.items = response.data.data;
          this.pagesLength = response.data.last_page;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    onEditClick(item) {
      this.$router.push({
        path: "/orders/edit/" + item.id,
        query: this.$route.query || {},
      });
    },
    onDetailsClick(item) {
      this.selectedData = item;
      this.$refs.detailsModal.dialog = true;
    },
    deleteItem(item) {
      MyAxios.delete(`/orders/${item.id}/delete`)
        .then((response) => {
          console.log("delete", response);
          this.$root.$emit("toast", {
            text: "آیتم با موفقیت حذف شد.",
            type: "success",
          });
          this.GetList();
        })
        .catch((error) => {
          console.log("laws", error.response);
        });
    },
    updateItems(items) {
      this.items = items;
    },
    price(row) {
      return (
        new Intl.NumberFormat().format(
          row?.order_product?.reduce(
            (all, current) =>
              all + (current.price ? parseInt(current.price) : 0),
            0
          )
        ) + " تومان"
      );
    },
  },
  data() {
    return {
      loading: true,
      items: [],
      selectedData: {},
      page: 1,
      pagesLength: 1,
      path: "/orders/index",
      pathParams: {
        with: ["orderProduct", "user"],
        conditions: {
          user_id: this.$store.state?.userData?.id,
        },
      },
      headers: [
        {
          text: "کد",
          align: "start",
          value: "code",
        },
        { text: "تاریخ", value: "created_at" },
        // { text: "وضعیت", value: "status" },
        { text: "قیمت", value: "price" },
        { text: "عملیات", value: "actions", sortable: false },
      ],
    };
  },
};
</script>

<style></style>
